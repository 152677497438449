import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Row,
  Input,
  Label,
  Col,
  Button,
  Badge,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
import moment from "moment";

function Dashboard() {
  const { user } = useContext(AuthContext);
  const URL_INFO = process.env.REACT_APP_URL_INFO;
  const URL_POSICIONES = process.env.REACT_APP_URL_POSICIONES;
  const URL_ORDENES = process.env.REACT_APP_URL_ORDENES;
  const URL_SALDO_USERS = process.env.REACT_APP_URL_SALDO_USERS;
  const URL_TRADES = process.env.REACT_APP_URL_TRADES;
  const URL_DEPOSITOS = process.env.REACT_APP_URL_DEPOSITOS;

  let hoy = new Date();
  let inicio = new Date();
  let fin = new Date();
  inicio.setDate(inicio.getDate() - 30);
  fin.setDate(fin.getDate() + 1);

  let endDate = moment(fin).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [actualizado, setActualizado] = useState(false);
  const [ordenes, setOrdenes] = useState([]);
  const [posiciones, setPosiciones] = useState([]);
  const [markPrice, setMarkPrice] = useState(0);
  const [saldo, setSaldo] = useState([]);
  const [trades, setTrades] = useState([]);
  const [total, setTotal] = useState(0);

  const [saldoInicial, setSaldoInicial] = useState(0);
  const [saldoFinal, setSaldoFinal] = useState(0);
  const [cantidadDias, setCantidadDias] = useState(0);
  const [utilidad, setUtilidad] = useState(0);
  const [porcentajeUtilidad, setPorcentajeUtilidad] = useState(0);
  const [depositos, setDepositos] = useState(0);

  const [fechaInicio, setFechaInicio] = useState(startDate);
  const [fechaFin, setFechaFin] = useState(endDate);

  const widgetRef = useRef(null);

  useMemo(() => {
    if (user.id) {
      axios
        .get(`${URL_INFO}GetOpenOrders/${user.id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          setActualizado(true);
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_DEPOSITOS}UserFechas/${user.id}/${fechaInicio}/${fechaFin}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allDepositos = response.data;
          let TEfe = allDepositos.map((c) => parseFloat(c.amount));
          let TE = TEfe.reduce((t, total, index) => t + total, 0);
          setDepositos(TE)
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(
          `${URL_SALDO_USERS}UserFechas/${user.id}/${fechaInicio}/${fechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allSaldo = response.data;

          let agrupado = allSaldo.reduce(function (groups, item) {
            const val = item["fecha"] 
        groups[val] = groups[val] || {
          fecha: item.fecha,
          saldo: 0,
        };
        groups[val].saldo += item.saldo;
        groups[val].fecha = item.fecha;
        return groups;
      },[])


      let arraySaldo = Object.values(agrupado)

          setSaldo(arraySaldo);

          const saldosOrdenados = arraySaldo.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

          // Obtener saldo inicial y final
          const saldoInicial = saldosOrdenados[0]?.saldo || 0;
          const saldoFinal = saldosOrdenados[saldosOrdenados.length - 1]?.saldo || 0;

          const utilidad = saldoFinal - saldoInicial - depositos
  
          // Calcular porcentaje de utilidad
          const porcentajeUtilidad = saldoInicial
            ? (utilidad / (saldoInicial + depositos)) * 100
            : 0;
  
          const fechaInicial = new Date(saldosOrdenados[0]?.fecha);
          const fechaFinal = new Date(saldosOrdenados[saldosOrdenados.length - 1]?.fecha);

          // Truncar las fechas a medianoche (solo día, mes, año)
          const fechaInicialUTC = new Date(fechaInicial.getFullYear(), fechaInicial.getMonth(), fechaInicial.getDate());
          const fechaFinalUTC = new Date(fechaFinal.getFullYear(), fechaFinal.getMonth(), fechaFinal.getDate());

          // Calcular la cantidad de días entre las fechas
          const cantidadDias = (fechaFinalUTC - fechaInicialUTC) / (1000 * 60 * 60 * 24);

          setSaldoInicial(saldoInicial);
          setSaldoFinal(saldoFinal);
          setCantidadDias(cantidadDias);
          setUtilidad(utilidad);
          setPorcentajeUtilidad(porcentajeUtilidad);
        })
        .catch((err) => {
          console.log(err);
        });

      if (actualizado) {
        axios
          .get(`${URL_POSICIONES}User/${user.id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allPosiciones = response.data;
            setMarkPrice(allPosiciones[0].markPrice);
            setPosiciones(allPosiciones);
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(`${URL_ORDENES}User/${user.id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allOrdenes = response.data;
            setOrdenes(allOrdenes);
          })
          .catch((err) => {
            console.log(err);
          });

          axios
          .get(`${URL_TRADES}UserFechas/${user.id}/${fechaInicio}/${fechaFin}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allTrades = response.data;
            setTrades(allTrades);

            let TEfe = allTrades.map((c) => parseFloat(c.realizedPnl - c.commission));
            let TE = TEfe.reduce((t, total, index) => t + total, 0);
          
            setTotal(TE)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [actualizado, user]);


  useEffect(() => {
    if (!document.getElementById("tradingview_script")) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.id = "tradingview_script";
      script.async = true;
      script.onload = () => {
        if (window.TradingView) {
          new window.TradingView.widget({
            container_id: "tradingview_chart",
            width: "100%",
            height: 400,
            symbol: "BINANCE:BTCUSDT.P",
            interval: "15",
            timezone: "America/Mexico_City",
            theme: "light",
            style: "1",
            locale: "es",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            hide_side_toolbar: false,
                  overrides: {
              "mainSeriesProperties.candleStyle.upColor": "#4f52f7", // Color de velas alcistas
              "mainSeriesProperties.candleStyle.downColor": "#d2d2d9", // Color de velas bajistas
              "mainSeriesProperties.candleStyle.borderUpColor": "#4f52f7", // Borde de velas alcistas
              "mainSeriesProperties.candleStyle.borderDownColor": "#d2d2d9", // Borde de velas bajistas
              "mainSeriesProperties.candleStyle.wickUpColor": "#4f52f7", // Mecha de velas alcistas
              "mainSeriesProperties.candleStyle.wickDownColor": "#d2d2d9", // Mecha de velas bajistas
            }
          });
        }
      };

      
      document.body.appendChild(script);
    }
  }, []);

  async function Actualizar() {
    setActualizado(false);
    axios
      .get(`${URL_INFO}GetOpenOrders/${user.id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setActualizado(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const renderGraficaSaldo = () => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={saldo} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="fecha" />
        <YAxis scale="log" domain={['dataMin', 'dataMax']} />

        <Tooltip />
        <Line type="monotone" dataKey="saldo" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
  

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-10">
          <h3 align="center">Dashboard</h3>
          <Row>
            <Col md={8}>
              <h3>
                Precio Actual:{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(markPrice)}
              </h3>
            </Col>
            <Col md={2}>
              <h4 align="right">
                <Badge
                  color={actualizado ? "success" : "danger"}
                  className="BadgeSize badge-pill"
                  onClick={Actualizar}
                >
                  {actualizado ? "Sync " : "NO Sync "}
                  <i class="fas fa-sync-alt" onClick={Actualizar}></i>
                </Badge>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Posiciones</h3>
            </Col>
          </Row>

          <Table striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Precio de Entrada</th>
                <th>P&L</th>
              </tr>
            </thead>
            {posiciones.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <h3 align="center">No hay posiciones abiertas</h3>
                </td>
              </tr>
            ) : (
              <tbody>
                {posiciones.map((a) => (
                  <tr>
                    <td>{a.cuenta == 1 ? "Long" : "Short"}</td>
                    <td>
                      {a.positionAmt} BTC
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.entryPrice)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.unRealizedProfit)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>

          <Row>
            <Col>
              <h3>Ordenes Pendientes</h3>
            </Col>
          </Row>

          <Table striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Tipo</th>
              </tr>
            </thead>
            {ordenes.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <h3 align="center">No hay ordenes abiertas</h3>
                </td>
              </tr>
            ) : (
              <tbody>
                {ordenes
                  .sort((a, b) => (a.price < b.price ? 1 : -1))
                  .map((a) => (
                    <tr>
                      <td>{a.side}</td>
                      <td>
                        {a.origQty} BTC
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.price)}
                      </td>
                      <td>{a.reduceOnly ? "Take Profit" : "Entrada"}</td>
                      <td>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>

          <div>
            <h3>Gráfica de Bitcoin</h3>
            <div ref={widgetRef} id="tradingview_chart" />
          </div>
          <br />

          <div className="saldo-cuenta container col-sm-8" >
            <h3>Saldo en tu cuenta</h3>
            <Table>
              <tbody>
                <tr>
                  <td><strong>Saldo Inicial:</strong></td>
                  <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoInicial)}</td>
                </tr>
                <tr>
                  <td><strong>Depósitos:</strong></td>
                  <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(depositos)}</td>
                </tr>
                <tr>
                  <td><strong>Saldo Final:</strong></td>
                  <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(saldoFinal)}</td>
                </tr>
                <tr>
                  <td><strong>Días Transcurridos:</strong></td>
                  <td>{cantidadDias}</td>
                </tr>
                <tr>
                  <td><strong>Utilidad:</strong></td>
                  <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(utilidad)}</td>
                </tr>
                <tr>
                  <td><strong>Porcentaje Utilidad:</strong></td>
                  <td>{new Intl.NumberFormat("en-US", {style: "percent",minimumFractionDigits: 2,maximumFractionDigits: 2,}).format(porcentajeUtilidad / 100)}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="grafica-saldo-container">
          {renderGraficaSaldo()}
        </div>

          <br />

          <Row>
            <Col>
              <h3>Trades</h3>
            </Col>
          </Row>

          <Table striped borderless className="table-responsive-xl">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>P&L</th>
              </tr>
            </thead>
            {trades.length === 0 ? (
              <tr>
                <td colSpan="6">
                  <h3 align="center">No hay Trades cerrados</h3>
                </td>
              </tr>
            ) : (
              <tbody>
                {trades
                .sort((a, b) => (a.time < b.time ? 1 : -1))
                .map((a) => (
                  <tr>
                    <td>{new Date(a.time).toLocaleString()}</td>
                    <td>{a.cuenta == 1 ? "Long" : "Short"}</td>
                    <td>
                      {a.qty} BTC
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.price)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(a.realizedPnl - a.commission)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">Total</td>
                  <td className="negrita">{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(total)}</td>
                </tr>
              </tbody>
            )}

          </Table>

        <br />
        </div>
      ) : undefined}
    </>
  );
}

export default Dashboard;
