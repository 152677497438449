import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Dashboard
import Dashboard from './views/Dashboard/Dashboard'
import ControlPanel from "./views/Dashboard/ControlPanel.jsx";

// Whastapp
import Whatsapp from "./views/WhatsApp/Whatsapp";

// Config
import Config from "./views/Config/Config.jsx";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";

// Cargos
import ListadoCargos from "./views/Cargos/ListadoCargos"
import CargosCreate from "./views/Cargos/CargosCreate"

// Abonos
import ListadoAbonos from "./views/Abonos/ListadoAbonos"
import AbonosCreate from "./views/Abonos/AbonosCreate"

// Estado Cuenta
import EstadoCuenta from "./views/EstadoCuenta/EstadoCuenta";
// Gastos
import BotonesGastos from "./views/Gastos/BotonesGastos"
import ListadoGastosGeneral from './views/Gastos/GastosGeneral/ListadoGastosGeneral'
import GastosGeneralCreate from './views/Gastos/GastosGeneral/GastosGeneralCreate'
import ListadoConceptosGastos from './views/Gastos/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/Gastos/ConceptosGastos/ConceptosGastosCreate'

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />

                {/* Config */}
                <Route exact path ="/Config" component={Config} />

                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />

                {/* Dashboard */}
                <Route exact path ="/Dashboard" component={Dashboard} />
                <Route exact path ="/ControlPanel" component={ControlPanel} />
               
                {/* Whatsapp */}
                <Route exact path ="/Whatsapp" component={Whatsapp} />

                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />

                 {/* Cargos */}
                 <Route exact path ="/ListadoCargos" component={ListadoCargos} />
                <Route exact path ="/CargosCreate" component={CargosCreate} />

                {/* Abonos */}
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />

                {/* Edo Cta */}
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />

                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Gastos" component={ListadoGastosGeneral} />
                <Route exact path ="/GastosCreate" component={GastosGeneralCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
